@font-face {
    font-family: IBMPlexSansHebrewRegular;
    src: url(../font/IBMPlexSansMedium.ttf);
}
@font-face {
    font-family: LinotypeDidotRoman;
    src: url(../font/LinotypeDidotRoman.ttf);
}
@font-face {
    font-family: IBMPlexSansHebrewBold;
    src: url(../font/IbmPlexSansHebrewBold.ttf);
}

body , h1 , h2 , h3 ,h4 , h5 ,h6 , p, button , a , *{
    font-family: IBMPlexSansHebrewRegular;
}
h1.diltoFnt{
    font-family: LinotypeDidotRoman;
}
 
  .active_set_tabs{
      color: rgba(248, 166, 111, 1);
      font-family: IBMPlexSansHebrewBold;
  }
  .header_icons.navbar-nav a {
    padding: 0px 12px !important;
}
/* general css */
.settings_section {
    max-width: 900px;
    margin: auto;
    padding: 35px 15px;
}

.row_settings {
    display: flex;
    flex-wrap: wrap;
}

.settings_col_left {
    width: 30%;
    border-right: 1px solid #BFC3C7;
}
.settings_col_right {
    width: 70%;
    padding-left: 50px;
}
.settings_col_left h1 {
    text-transform: uppercase;
    font-size: 24px;
    font-family: IBMPlexSansHebrewBold;
    padding-top: 7px;
}
ul.settings_tabs {
    list-style: none;
    padding-top: 15px;
    padding-left: 0px;
}
.settings_tabs li {
    cursor: pointer;
    padding: 6px 0px;
    font-size: 18px;
}
p.first_l {
    font-size: 15px;
    line-height: 19.5px;
    font-family: IBMPlexSansHebrewBold;
    margin-bottom: 0px;
}
p.sec_l {
    color: #69747D;
    font-size: 15px;
    margin-bottom: 0px;
    line-height: 1.4;
}
.field {
    padding-bottom: 18px;
}
.settings_col_right input, .settings_col_right select, .settings_col_right input.album_date{
    color: #283845;
    font-size: 18px;
    height: 42px;
    width: 100%;
    border: 1px solid #283845;
    padding: 0px 10px;
    background: transparent;
}
.settings_col_right input, .settings_col_right select {
    margin-top: 5px;
}
.settings_btns button {
    background: rgba(248,166,111,1);
    border: none;
    height: 47px;
    cursor: pointer;
    color: #fff;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0px 30px; 
}
.settings_btns button.bg-gray{
    background-color: #283845;
}
.settings_btns button:disabled
{
    background: #EAEBEC;
    color: #BFC3C7;
}
.settings_btns button:last-child {
    margin-left: 20px;
}
.field.checkboxed {
    display: flex;
    position: relative;
}
.field.checkboxed input[type="checkbox"] {
    border: 1px solid #000!important;
    background: transparent!important;
    border-radius: 6px;
}
.labels_check {
    padding-left: 34px;
}
.labels_check p.first_l {
    font-size: 18px;
}
.field.selectboxes {
    display: flex;
    align-items: center;
}

.field.selectboxes .selsect_labels {
    flex: 1;
}
.field.selectboxes select.select_cate_bx {
    background: #283845;
    color: #fff;
    width: 125px;
    height: 40px;
    padding: 0px 10px;
}
.field.selectboxes .selsect_labels {
    border-left: 2px solid;
    padding-left: 10px;
}
.delete-name {
    color: #EB5757;
    font-size: 18px;
    text-decoration: underline;
}


::placeholder{
  color: #808080f2;  
}
.settings_btns {
    padding-top: 20px;
}
.email_with_plus_ic:before {
    content: "+";
    right: 18px;
    position: absolute;
    top: 14px;
    font-size: 21px;
    border: 1px solid #000;
    border-radius: 100%;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.email_with_plus_ic {
    position: relative;
}
.trash {
    display: flex;
    align-items: center;
}
.delete-name {
    padding-left: 5px;
}
.field.selectboxes p.first_l {
    font-size: 18px;
}

.field.selectboxes p.sec_l {
    font-size: 18px;
}
/* checkboxes */

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0px;
    width: 25px;
    height: 25px;
    z-index: 5;
}

span.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 4px;
    border: 1.5px solid #283845;
}
input[type="checkbox"]:checked ~ .checkmark {
  background-color: transparent;
  border: 1px solid #F8A66F;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
input[type="checkbox"]:checked ~ .checkmark:after {
  display: block!important;
}
.checkmark:after {
    left: 9px;
    top: 6px;
  width: 5px;
  height: 11px;
  border: solid #F8A66F;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
button.trash.btn.btn-primary {
    background: transparent;
    border: none;
    color: #EB5757;
    font-size: 18px;
    text-decoration: underline;
}
button.trash.btn.btn-primary img {
    padding-right: 6px;
}


/* delete popup */
.delete_popup .modal-title.h4 {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    color: #283845;
    line-height: 1.3;
    margin-bottom: 5px;
}
.delete_txt {
    color: #283845;
    font-size: 18px;
    line-height: 1.3;
}
.delete_in label, .password_a label {
    font-size: 15px;
    padding-bottom: 5px;
    line-height: 1.3;
}
.delete_in label input[type="text"] {
    width: 100%;
    height: 55px;
    border: 1px solid #283845;
    padding: 0px 12px;
    font-size: 20px;
    color: #69747D;
}
.delete_popup .modal-header {
    border: none;
    padding: 0px;
}
.delete_popup  .modal-body {
    padding: 0px;
}
.delete_popup .modal-content {
    padding: 50px;
    border-radius: 0;
    max-width: 467px;
    margin: auto;
}
.delete_popup .modal-dialog {
    max-width: 95%;
    top: 50%;
    transform: translateY(-50%) !important;
    margin: 0 auto;
}
.delete_popup .modal-footer {
    justify-content: flex-start;
    padding: 20px 0px 0px 0px;
    border: none;
}
.modal-footer > * {
    margin: 0;
}
.delete_popup input[type="text"], .password_a input[type="text"]{
    width: 100%;
    height: 55px;
    border: 1px solid #283845;
    padding: 0px 12px;
    color: #69747D;
    font-size: 20px;
}
button.btn-close {
    position: absolute;
    left: 0;
    top: 20px;
    right: 20px;
    color: oldlace;
}
.delete_popup .modal-footer button.btn.btn-dangrous {
    background: #EB5757;
    margin-left: 20px;
    border-radius: 0;
}

.delete_popup .modal-footer button.btn.btn-secondary {
    background: #69747D;
    width: 138px;
    height: 48px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: IBMPlexSansHebrewBold;
    color: #fff;
    border-radius: 0;
}
.modal-footer button.btn:hover {
    opacity: 0.7;
}

.delete_popup .modal-footer button{
    width: 138px;
    height: 48px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: IBMPlexSansHebrewBold;
    color: #fff;
}



/* router */
.albumPassword .ant-modal-footer button:first-child {
    display: none;
}
.albumPassword .ant-modal-footer button {
    text-align: center;
    margin: 0px !important;
    background: #F8A66F;
    border: none;
    align-items: center;
    height: 47px;
    padding: 0px 32px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 18px;
    font-family: IBMPlexSansHebrewBold;
}

.albumPassword .ant-modal-footer {
    text-align: center;
}
.albumPassword .ant-modal-content .ant-modal-title {
    color: #F8A66F;
    font-size: 24px;
    line-height: 1.3;
    text-transform: uppercase;
    font-family: IBMPlexSansHebrewBold;
    text-align: center;
}
.ant-modal.albumPassword {
    max-width: 466px;
    width: 100% !important;
    transform-origin: unset!important;
    top: 0;
    margin: auto;
}
.ant-modal.albumPassword .ant-modal-content > div {
    padding: 10px 0;
}
.ant-modal.albumPassword .ant-modal-content > div.ant-modal-body {
    padding: 20px 0;
}
.ant-modal-wrap {
    transform: translateY(-50%)!important;
    top: 50% !important;
    bottom: unset !important;
}
.albumPassword .ant-modal-header, .albumPassword .ant-modal-footer{
    border: none !important;
}
.albumPassword .ant-modal-content {
    padding: 30px 55px;
    max-width: 95%;
    margin: auto;
}
.albumPassword span.ant-modal-close-x {
    display: none;
}



.shareIcon {
    background: #fff;
    display: flex;
    padding: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    align-items: center;
    position: relative;
    top: -35px;
}

.share_ic {
    padding: 0px 10px;
}

.share_ic img {
    width: 30px;
}

.share_ic.close-share img {
    width: 16px;
}
a.link_i.share.nav-link {
    position: relative;
}
/* uploader */
.uploadinstatus {
    background: #fff;
    width: 100%;
    max-width: 302px;
    position: fixed;
    right: 20px;
    z-index: 99999;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 18px;
    bottom: -130px;
    transition: ease-in-out bottom 500ms;
   
}
.showstatus {
    bottom: 20px;
}
.upload_wrap {
    display: flex;
    align-items: center;
}
.upload_wrap p {
    flex: 1;
    font-size: 18px;
    margin: 0px;
}
.upload_btns {
    display: flex;
    align-items: center;
}
.upload_btns img {
    width: 30px;
}
.paus_btn {
    padding-right: 10px;
}
.progress_uploader {
    display: flex;
    width: 100%;
    padding-top: 15px;
}
.ant-progress-bg {
    background-color: #F8A66F !important;
    height: 8px !important;
    border-radius: 0px !important;
}
.ant-progress-inner {
    background: #EAEBEC;
    height: 8px;
    border-radius: 0px;
    margin: 0PX;
}
span.progstatus {
    color: #F8A66F;
    font-size: 15px;
    padding-right: 10px;
}
.ant-progress-outer {
    padding: 0px !important;
}
.header_icons.navbar-nav {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
}
/* responsive css */
@media (max-width: 1250px) {
.share_ic img {
 width: 22px !important;
}
.share_ic {
 padding: 0px 5px !important;
}
.share_ic.close-share img {
 width: 13px !important;
}
 .header_icons.navbar-nav a {
 padding: 0px 7px !important;
}
.header_icons.navbar-nav .link_i img {
    width: 27px;
 }
}
@media (min-width: 992px) and (max-width: 1250px) {
    .navbar-nav a {
        font-size: 14px;
        padding: 0px 7px !important;
}
}
@media(max-width:992px){
.header_icons.navbar-nav {
    position: unset !important;
}
.shareIcon {
    max-width: 200px;
}
a.link_i.share.nav-link {
    height: 65px;
}
.shareIcon.showIcon {
    top: -38px;
}
}
@media(max-width:980px){
.settings_col_left, .settings_col_right {
    width: 100%;
    padding-left: 0px;
}

.settings_col_left {
    border-right-width: 0px;
    border-bottom: 1px solid #BFC3C7;
}
.settings_col_right {
    padding-top: 20px;
}
ul.settings_tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
.settings_tabs li {
    padding: 0px 9px; 
    width: 33.33%;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
}
.settings_section {
    padding-top: 10px;
}

}
@media(max-width:500px){
.settings_btns button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
}

.settings_btns {
    display: flex;
    align-items: center;
}
.settings_btns button:last-child {
    margin-left: 12px;
}
.field.selectboxes select.select_cate_bx {
    font-size: 14px;
    width: 100%;
}
.field.selectboxes p.first_l {
    font-size: 15px;
}
.field.selectboxes p.sec_l {
    font-size: 12px; 
}
.labels_check p.first_l {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
}
.settings_tabs li {
    font-size: 15px;
}
.albumPassword .ant-modal-content {
    padding: 30px 20px;
 }
 .albumPassword .ant-modal-content .ant-modal-title{
     font-size: 20px;
 }
 .albumPassword .ant-modal-footer button{
     font-size: 16px;
 }
 .delete_popup .modal-content {
    padding: 40px 20px;
 }
 .delete_popup .modal-title.h4{
     font-size: 20px;
 }
 .delete_txt{
     font-size: 16px;
 }
 .delete_in label, .password_a label{
     font-size: 14px;
 }
 .delete_popup input[type="text"], .password_a input[type="text"]{
     font-size: 16px;
 }
 .delete_popup .modal-footer button.btn.btn-secondary ,  .delete_popup .modal-footer button.btn.btn-dangrous{
     width: 48%;
     font-size: 16px;
 }
 .delete_popup .modal-footer button.btn.btn-dangrous {
    margin-left: 4%;
}
p.sec_l{
    font-size: 16px;
}
.field.selectboxes p.sec_l {
    font-size: 14px;
}
.settings_tabs li {
    font-size: 16px;
    width: auto;
    padding: 0 15px;
}
.settings_col_left h1{
    font-size: 30px;
}
p.first_l{
    font-size: 16px;
    line-height: 1.4;
}
}
@media (max-width: 360px){
    .settings_tabs li {
        font-size: 15px;
        padding: 0 10px;
    }
}
