/* MobMenu header */
.pauseicon .anticon svg {
    display: inline-block;
    font-size: 27px;
}
.active{
    border: 2px solid #F8A66F;
}
.pad10 .ant-image-mask {
    background: transparent;
}
.pad10 .ant-image-mask-info {
    display: none;
}

#overlayMenu{
    position: fixed;
    width:100%;
    height: 100%;
    right: -100%;
    top: 0;
    z-index: 9997;
    background-color: transparent;
    overflow-y: auto;
    transition: all 500ms ease-in-out;
}
#photomobhead {
  display: none;
}
#photomobhead  {
    position: fixed;
    z-index: 9999;
    opacity: 1;
    height: 100vh;
    width: 100%;
    top: 0;
    right: -100%;
    background-color: #FFF5EE !important;
    max-width: 300px;
    padding: 30px 20px;
    box-shadow: 0 -3px 10px rgb(0 0 0 / 20%);
    overflow-y: auto;
    transition: all 500ms ease-in-out;
}
.openMenu + #photomobhead, #overlayMenu.openMenu{
    right: 0;
}
#photomobhead  a{
    color: inherit;
}
#photomobhead  .row{
    margin: 0;
}
#photomobhead  .column {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #BFC3C7 ;
    color: #283845;
    font-weight: 500;
}
#photomobhead  .column:last-child {
    border-bottom: 0;
}
#photomobhead  .column a{
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
#photomobhead  .column p {
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
}
#photomobhead  .column img{
    max-width: 22px;
}
#photomobhead   .ant-collapse{
    border: 0;
}
#photomobhead   .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding: 0 !important;
}
#photomobhead   .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: inline-block;
    margin-right: 12px;
    font-size: 12px;
    vertical-align: -1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    margin-right: 0 !important;
}
#photomobhead   .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    transform: translateY(-50%) rotate(180deg);
}
#photomobhead  .ant-collapse > .ant-collapse-item {
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #BFC3C7 ;
    border-radius: 0;;
}
#photomobhead  .ant-collapse {
    background: transparent !important;
    border: 0;
}
#photomobhead  .ant-collapse-header{
    font-size: 16px;
    font-family: IBMPlexSansHebrewBold;
    text-transform: uppercase;
    color: #283845;
}
#photomobhead  .ant-collapse-item-active .ant-collapse-header{
    color: #F8A66F !important;
}
#photomobhead   .ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: transparent;
    border-top: 0;
    padding: 0;
}
#photomobhead   .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px 0 !important;
}
#photomobhead .menulist  .ant-collapse-content > .ant-collapse-content-box ul{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
    padding: 0;
    margin: 0;
}
#photomobhead  .menulist .ant-collapse-content > .ant-collapse-content-box ul li:not(:last-child){
    margin-bottom: 15px;
}
#photomobhead  .ant-collapse-content > .ant-collapse-content-box ul li{
    font-size: 16px;
    width: 100%;
    text-align: left;
    padding: 0;
} 
#photomobhead .socialicons .ant-collapse-content-box > ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.row {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.column {
    float: left;
    width: 33.33%;
    padding: 5px;
    display: flex;
  }
  .collapswarp ul li {
    list-style: none;
}
.socialicons ul li {
    list-style: none;
}
.errormsg{
    color: red;
}
.perplus {
    position: relative;
}
.perplus span.anticon.anticon-plus-circle {
    position: absolute;
    right: 10px;
    top: 57%;
    transform: translateY(-50%);
    font-size: 20px;
}
.navbar{
    width: 100%;
    background-color: #FFF5EE !important;
}
span.navbar-brand {
    padding: 0px!important;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    padding-top: 10px;
    padding-bottom: 10px;
}
.navbar ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
    align-items: center;
}
.navbar-nav a.active-menu {
    color: #F8A66F!important;
}
.navbar-brand img {
    max-width: 300px;
}
.navbar-nav a {
    font-size: 18px;
    color: #283845 !important;
    font-weight: 400;
    padding: 0px 24px !important;
    text-decoration: none;
    transition: color 150ms;
}
a:hover {
    color: #f8a66f!important;
}
.navbar-nav a:last-child {
    padding-right: 0px!important;
}
.header {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}
.add_photo:hover {
    color: #f8a66f;
}
.ant-progress-bg {
    background-color: #F8A66F;
}
.ant-progress-inner {
    background-color: #EAEBEC;
    margin-top: 10px;
}
span.progress-text {
    color: #F8A66F;
    font-size: 40px;
}
.details-add label {
    font-size: 15px;
}
.progress_bx {
    padding-top: 30px;
    padding-bottom: 40px;
}
.p_text_bar {
    display: block;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 15px;
}
.album_f button:before {
    content: ">";
}
.uploading {
    float: left;
    font-size: 18px;
}

.add_photo {
    float: right;
    font-size: 18px;
}
button.add_photo {
    background: transparent;
    border: none;
}
img.first {
    position: absolute;
    left: -55px;
    z-index: 2;
    max-width: 115px;
    top: 50%;
}
img.sec {
    position: absolute;
    right: -19px;
    z-index: 2;
    max-width: 55px;
    top: 30%;
}
img.third {
    position: relative;
    left: 0;
    top: 0 !important;
    right: 0;
    z-index: 0;
    margin: auto;
    max-width: 100px;
}
.gallerybox {
    position: relative;
    max-width: 125px;
    margin: auto;
}

.album-details h2 {
    font-family: IBMPlexSansHebrewBold;
    font-size: 24px;
    text-transform: uppercase;
    color: #283845;
    margin-bottom: 10px;
}
.album_f label {
    font-size: 15px;
}
.details-add {text-align: left;}
.album_f input[type="text"], select.category, input[type="date"]{
    border: 1px solid #283845;;
    height: 48px;
    width: 100%;
    padding: 1px 10px;
    margin-top: 5px;
    background: transparent;
}
.container {
    max-width: 98%;
    margin: auto;
    padding: 0px 15px;
}
.album_f button {
    background: #283845;
    border: 1px solid #283845;
    height: 48px;
    color: #fff;
    font-family: monospace;
    font-size: 22px;
    width: 35px;
    cursor: pointer;
    margin-top: 5px;
}
.album_f {
    display: flex;
    align-items: center;
    
}
.steps {
    max-width: 375px;
    margin: auto;
    width: 100%;
}
.album2 .album_f{
    flex-wrap: wrap;
}
.add_photo {
    text-decoration: underline;
    cursor: pointer;
    transition: color 150ms;
}
button.create_album {
    width: 100%;
    margin-top: 10px;
    background: #EAEBEC;
    border: none;
    height: 48px;
    color: #BFC3C7;
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
}
.cate_album {
    width: 50%;
}
.date_album {
    width: 50%;
}
.cate_album select.category {
    width: 100%;
}
.date_album {
    padding-left: 6px;
}
.back {
    text-decoration-line: underline;
    font-size: 16px;
    padding-top:0;
    position: relative;
    padding-left: 12px;
    cursor: pointer;
    transition: color 150ms;
}
.back:hover {
    color: #f8a66f;
}
.back:before {
    content: "<";
    position: absolute;
    left: 0;
    font-family: monospace;
    margin-top: 0;
}
.dontHave .right_sec {
    padding: 80px 42px;
}
.dontHave .album-details {
    padding: 20px 0px;
}
.right_wrap {
    width: 100%;
}
@media(max-width:991px){
.left_sec {
    width: 100%;
}
.right_sec {
    width: 100%;
}

div#basic-navbar-nav {
    position: absolute;
    background: #FFF5EE;
    width: 100%;
    left: 0;
    top: 0;
    height: 200vh;
    padding: 40px 18px;
    z-index: 9;
}
button.navbar-toggler {
    position: absolute;
    right: 12px;
    z-index: 55;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}
.navbar-nav a, nav .header_icons.navbar-nav a {
    padding: 14px 0px !important;
    border-bottom: 1px solid #BFC3C7;
}
button.navbar-toggler.collapsed {
    border: none !important;
    padding: 0px;
    color: #000!important;
    font-size: 18px;
}
.navbar-light .navbar-toggler {
    border: none !important;
    padding: 0px;
    font-size: 18px;
}
.right_sec {
    height: 480px;
    width: 100%;
    right: 0;
    margin-right: auto;
    left: 0;
    top:60%;
}
.upload-img img {
    max-width: 60px;
}
.right_sec_heading h1, .right_sec_heading h2 {
    font-size: 30px;
}
.right_sec_heading h2 {
    font-size: 25px;
}
span.progress-text {
    font-size: 30px;
}
img.first {
    left: -50px;
    max-width: 98px;
}
img.sec {
    right: -19px;
    max-width: 55px;
}
img.third {
    top: -49px;
    right: 0;
    max-width: 100px;
}
.progress_bx {
    padding-top: 10px;
}
.album2 .right_sec {
    height: 600px;
}
section.row.album2.stpes_row .right_sec {
    position: unset;
    transform: unset!important;
    margin-top: 30px;
    height: 655px;
}
section.row.album2.stpes_row .right_sec {
    width: 100%;
}
section.row.album2.lats_create .right_sec {
    height: 480px;
}
}
@media(max-width:767px){
section.row {
    flex-wrap: wrap;
}
.steps {
    max-width: 433px;
    margin: auto;
}
.navbar ul li {
    padding: 0px 10px;
}
.navbar li {
    font-size: 13px;
}
.dropphotos .right_sec {
    padding: 35px 10px;
}
section.row.album2 .album_f {
    display: block;
}
section.row.album2 .album_f input[type="date"] {
    width: 100%;
    padding-right: 0px;
}
.date_album {
    padding-left: 0px;
    margin: 12px 0px;
}
section.row.album2 .right_sec, section.row.album1 .right_sec {
    padding-bottom: 30px;
}
.date_album, .cate_album {
    width: 100%;
}
.cate_album select.category {
    width: 100%;
}
.settings_col_right input, .settings_col_right select, .settings_col_right input.album_date {
    font-size: 16px !important;
}
.navbar-brand img {
    max-width: 180px;
}
.right_sec {
    width: 92%;
    max-width: 100%;
}

}

@media(min-width:992px){
    #photomobhead {
        display: none;
      }
}
@media (max-width: 1250px){
    .navbar-brand img {
        max-width: 220px;
    }
}
@media (max-width:991px) {
    div#basic-navbar-nav{
        display:none;
    }
    #photomobhead {
        display: block;
      }
     
}
@media (max-width: 767px){
    .rltvBox .back {
        padding: 5px 0;
        padding-left: 25px;
        background: url(../images/galleryMobile.png) no-repeat left center !important;
        background-size: 20px auto !important;
        position: absolute;
        top: 95px;
        z-index: 9;
        right: 10px;   
        font-size: 14px;     
    }
    .rltvBox  .back:hover {
        background: url(../images/gallery.png) no-repeat left center !important;
        background-size: 20px auto !important;
    }

    .rltvBox .back:before {display: none;}

}
@media(max-width:500px){
.logo img {
    max-width: 130px;
}

.add_photo,.uploading {
    font-size: 14px;
}

}


button.fbLogin {
    background: rgba(248, 166, 111, 1);
    border: navajowhite;
    color: #fff;
    padding: 12px 40px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 15px;
    font-family: IBMPlexSansHebrewBold;
    margin-left: 35px;
    margin-top: 16px;
}

