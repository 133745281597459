/* Photographer */
.photo_gallery_inner button.active_tabs p {
    border-bottom: 2px solid;
    font-weight: 600;
}
button.back {
    background-color: none;
    border: none;
    background: none;
    margin: 0 auto;
    display: block;
}





/* footer */
.footer img {
    max-width: 135px;
}
.footer_cont {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer_cont span {
    padding: 0px 8px;
}
button.selfie_btn {
    background-color: #F8A66F;
    border: none;
    width: 279px;
    height: 23px;
    display: block;
}
.slide_bx {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
}
.slide-item-bx:before {
    content: "8";
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    bottom: 2px;
    left: 15px;
}
.guest_block {
    text-align: center;
}
.guest_block .gallery_block_btns img{
    border: 4px solid #F8A66F;
    margin-right: 10px;
}
.change_selfi_img:before {
    content: "8";
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    bottom: 2px;
    left: 8px;
}
img.change_selfie {
    width: 100%;
    max-width: 115px;
    border: 4px solid #F8A66F;
}

.gallery_block_btns.change {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 50px 0px;
}
.change button {
    width: 214px;
    padding: 12px 0px;
}
p.back_to_gallery {
    font-size: 18px;
    text-decoration: underline;
    padding-top: 5px;
    cursor: pointer;
    transition: color 150ms;
}
p.back_to_gallery:hover {
    color: #F8A66F;
}
.guest_gallery_list.container {
    padding-bottom: 20px;
}
.guests_slide {
    position: relative;
    padding: 0px 83px;      
}
.slider_warap, .slider_warapper{
    margin: auto;
    width: 100%;
    overflow-x: unset!important;
    max-width: 1000px;
}
span.clearAll {
    background: #69747D;
    color: #fff;
    padding: 5px 9px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)!important;
    left: -90px;
}
span.clearAll:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 10px 8px 0;
    border-color: transparent #69747D transparent transparent;
    top: 9px;
    transform: rotate( 
180deg);
    right: -10px;
}
.slide-item-bx:before {
    left: 18px;
}

ul.react-multi-carousel-track {
    justify-content: center;
    align-items: center;
}
/* button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 38px;
}
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 38px;
} */
.slide-item-bx img.sliderimg {
    width: 100%;
    border: 4px solid #F8A66F;
}
.slide-item-bx {
    padding: 0px 10px;
}
.react-multiple-carousel__arrow {
    background: transparent !important;
}
.react-multiple-carousel__arrow::before {
    font-size: 32px !important;
    color: #212529 !important; 
}
.shareIcon.hideIcon {
    position: absolute;
    left: -50%;
    transform: translateX(-50%);
    top: -299%;
}
.shareIcon.showIcon {
    position: relative;
    left: unset;
    transform: unset !important;
    top: -40px;
}

/* responsive css */
@media(max-width:1225px) {
.slider_warap, .slider_warapper {
    max-width: 550px;
}
li.react-multi-carousel-item {
    width: 96px!important;
}
}
@media(max-width:767px) {
.slider_side_img {
    display: none !important;
}
.slide_bx {
    width: 100%;
}
.guests_slide {
    width: 100%;
}
.slider_warapper {
    width: 100%;
}
.guest_item {
    width: 25%;
    padding: 8px;
}
.gallery_block_btns button {
    font-size: 16px;
    margin: 5px;
}
h3.gallery_h {
    font-size: 30px;
}
.slide_i {
    width: 20%;
    padding: 0px 3px;
}
.slide_bx .slide_i img {
    width: 100%; 
}
.mob-scenes {
    display: flex!important;
    padding-top: 12px;
}
.secnse_col.all_clear {
    width: 50%;
    text-align: left;
}
.secnse_col.all_show {
    width: 100%;
    text-align: right;
}
.secnse_col p {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}
.secnse_col.all_show span {
    padding-left: 8px;
}
.photo_gallery_inner button:last-child {
    display: none;
}
.slider_warap {
    max-width: 100%;
    padding: 0px 15px;
}
.slide_i:before, .change_selfi_img:before, .slide-item-bx:before{
    font-size: 14px !important;
}
.slider_warap, .slider_warapper {
    max-width: 100%;
}
.guests_slide {
    padding: 0px 0px;
}
.react-multiple-carousel__arrow{
    display: none;
}
.row_gal {
    display: grid;
    grid-gap: 10px;
}
img.change_selfie {
    max-width: 70px;
}
}